@import '../../../tokens/tokens.scss';

.satisfaction-company {
    display: flex;
    background-color: white;
    -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    box-shadow: -2px 4px 7px 0px #d6d6d6;
    border: 1px solid $color-secondary-gray-nielsen;
    //border-radius: .8rem;
    width: 100%;
    max-width: 300px;
    margin-bottom: 300px;

    @media (max-width: 1310px) {
        max-width: 90%;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
    }

    & .aj-responsive {
        @media (max-width: 1310px) {
            display: flex;
        }

        @media (max-width: 1020px) {
            display: flex;
            flex-direction: column;
        }
    }

    &__medal {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;


        &>p {
            font-size: 1.1rem;
            text-transform: uppercase;
            color: $primary-text-color;
            border-bottom: 1px solid;
            margin-bottom: 35px;
        }


        & .medal-label {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            & .heart-color{
                color: $error-dark-niq;
            }

            & .calendar-color{
                color: $primary-green-niq;
            }

            & .comments-color{
                color: black;
            }

            & span {
                font-size: 18px;
                margin-left: 8px;
                color: $primary-text-color;
            }
        }

        & img {
            width: 50px;
        }

        &__percentages {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;

            @media (max-width: 1310px) {
                margin: auto 10px;
            }

            & span {
                font-size: 25px;
                margin-left: 15px;
            }

            & .progress {
                height: 22px;
                width: 240px;
                font-size: 1.2rem;
                font-style: italic;
                

                &>span {
                    font-size: 5rem;
                }
            }

            & .progress-bar {
                background-color: $darker-green-niq;
                //color: black;
            }
        }

    }

}