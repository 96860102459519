@import '../../../tokens/tokens.scss';

// .teste-bg{
//     //background-color: #e9ecef87;
//     //height: 80px;
//     //box-shadow: 0px -5px 6px -5px #f0f0f0

//   }

.company-reputation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    &__title {
        font-size: 40px;
        font-weight: 600;
    }

    &__sub-title {
        font-size: 20px;
    }
}

.store-search {
    display: flex;
    align-items: center;
    justify-content: start;
    //width: 800px;

    &>input {
        width: 50%;
        padding: 10px;
        border: 1px solid;
        border-color: black;
        //box-shadow: 0px 3px 6px 0px #d6d6d6a1;
        margin-bottom: 50px;        

        @media (max-width: 994px) {
            width: 90%;
          }

          @media (max-width: 550px) {
            width: 100%;
          }

        &:focus {
            border-color: #00A346;
            outline: none;
        }
    }    
}

.new-nav-NIQ{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    gap: 15px;
    font-family: Montserrat;
    font-weight: 500;
    

    & .btn-navIQ{
        display: flex;
        align-items: center;
        padding: 3px 20px;
        border: 1px solid black;
        cursor: pointer;
        width: 250px;

        &:hover{
            border-color: #00A346;
        }
       

        &>img{
            width: 20px;
            margin-right: 5px;
        }

        &>p{
            margin-top: 2px;
        }        
    }
}




.menu-reputation {
    margin-top: 50px;
    background-color: white;
    margin-bottom: 10px;
    //padding-top: 20px;

    .nav-item{

        @media (max-width:991px) {
            width: 50%;
            flex-basis: 1;
        }
        @media (max-width:575px) {
            width: 100%;
            flex-basis: 1;
        }
    }
}

.nav-categories {
    display: flex;
    justify-content: center;
    align-items: center;

    & span{
        color: $shades-gray50;
        white-space: nowrap;

        &:hover{
            color: black;
        }
    }

    &:active{
        background-color: #e9ecef87;
    }

    &>img {
        height: 40px;
        margin-right: 10px;
    }
}

.legend-satisfaction {
    margin-top: 35px;
    display: flex;
    justify-content: space-evenly;
    //position: fixed;

    &__icon {
        display: flex;
        justify-content: center;
        font-size: 25px;

        &.delivery-time {
            color: $primary-green-niq;
        }

        &.return-purchase {
            color: $error-dark-niq;
        }

        &.friend-recommendation {
            color: $color-primary-black-nielsen;
        }
    }

    &__text {
        font-size: 18px;
        color: $color-primary-gray-nielsen;
    }
}


.warning-company {
    width: 100vw;
    height: 40vh;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: .8rem;
    margin-bottom: 100px;

    border-color: $color-secondary-gray-nielsen;
    -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);

    & h1{
        font-size: 30px;
        padding: 35px;
    }




}