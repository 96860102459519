@import "../../../tokens/tokens.scss";

.company-card {
  background-color: white;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: solid 1px;
  border-color: $color-secondary-gray-nielsen;
  cursor: pointer;
  width: 100%;

  &:hover {
    border-color: $color-primary-gray-nielsen;
    text-decoration: none;
  }

  &__content {
    background-color: $color-primary-white-nielsen;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: -20px;
      padding: 8px;
      width: 100%;
      margin-left: 10px;
      margin-bottom: 20px;

      @media (max-width: 1200px) {
        flex-direction: column;
      }

      &__logo {
        // max-height: 85px;
        max-width: 110px;
      }

      & .rebranding-01 {
        display: flex;
        align-items: center;
        font-family: Montserrat;

        & > img {
          margin-left: 7px;
          width: 35px;
        }
      }
    }

    &__infos {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      & > p {
        color: $color-primary-gray-nielsen;
        font-size: 15px;
        text-transform: uppercase;
      }

      & > img {
        width: 40px;
        //opacity: .6;
        margin-bottom: 10px;
      }

      &__display-percentage {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;

        &__satisfaction {
          display: flex;
          flex-direction: column;
          margin: 10px;
          padding: 10px;
          font-family: Montserrat;
          //border: solid 1px;

          @media (max-width: 768px) {            
            width: 100%;
          }

          & .progress {
            width: 250px;
            height: 30px;
            border-radius: 0;
            //border: 1px solid black;

            @media (max-width: 768px) {
                width: auto;
              }

            .progress-bar {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-direction: column;
              flex-direction: column;
              -ms-flex-pack: center;
              justify-content: center;
              overflow: hidden;
              color: #fff;
              text-align: center;
              white-space: nowrap;
              background-color: #00a346;
              transition: width 0.6s ease;
            }
          }

          &__icons {
            margin-right: 10px;
            font-size: 30px;

            &.delivery-time {
              color: $primary-green-niq;
            }

            &.return-purchase {
              color: $error-dark-niq;
            }

            &.friend-recommendation {
              color: $color-primary-black-nielsen;
            }
          }

          & > small {
            font-size: 10px;
            font-style: italic;
            color: $color-primary-gray-nielsen;
          }

          &__percentage {
            color: $color-primary-gray-nielsen;
            font-style: bold;
            font-size: 20px;
          }
        }
      }

      &__quantity-surveys {
        display: flex;
        flex-direction: column;
        color: #6c757d;
        font-size: 16px;
        font-family: Montserrat;
        color: black;

        @media (max-width: 1200px) {
            padding-left: 20px;
          }

        & .precent-survey {
          color: #00a346;
          font-size: 30px;
        }
      }
    }
  }
}
